
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

/* Layout */
import Layout from '@/layout'

export const constantRoutes = [
  {
    path: '/login',
    name: 'login',
    meta: { title: '登录', hidden: true },
    component: () => import('@/views/Login')
  },
  {
    path: '/',
    name: 'Home',
    redirect: '/home',
    component: Layout,
    meta: { title: '首页', hidden: true },
    children: [
      {
        path: '/home',
        meta: { title: '首页', hidden: true },
        component: () => import('@/views/EOP/WorkBench')
      },
      {
        path: '/home/AddRole',
        meta: { title: '新增角色', hidden: true },
        component: () => import('@/views/EOP/User/AddRole')
      },
      {
        name: 'MiniProgramVersion',
        path: '/platform/mini-program-version',
        component: () => import('@/views/Platform/MiniProgramVersion'),
        meta: { title: '版本管理', noCache: true }
      }
    ]
  }
]

const createRouter = () =>
  new VueRouter({
    // mode: 'history', // require service support
    base: process.env.BASE_URL,
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
  })

const router = createRouter()

// 允许路由重复点击
/* eslint-disable no-undef */
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch((err) => err)
}

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router

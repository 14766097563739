import router from './router'
import store from './store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken } from '@/utils/auth'

NProgress.configure({ showSpinner: false })

const whiteList = ['/login', '/login/admin', '/account/forget', '/sso/authorize'] // no redirect whitelist

// eslint-disable-next-line space-before-function-paren
router.beforeEach(async (to, from, next) => {
  // auto login by token

  // start progress bar
  NProgress.start()

  // determine whether the user has logged in
  const hasToken = getToken()

  // console.log(to.fullPath, getToken())

  if (hasToken) {
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({ path: '/' })
      NProgress.done() // hack: https://github.com/PanJiaChen/vue-element-admin/pull/2939
    } else {
      const hasGetUserInfo = store.getters.account
      // console.log(hasGetUserInfo)

      if (hasGetUserInfo) {
        next()
      } else {
        try {
          await store.dispatch('user/getInfo')
          const accessRoutes = await store.dispatch('permission/generateRoutes', to)
          router.addRoutes(accessRoutes)
          next({ ...to, replace: true })
        } catch (error) {
          console.error(`动态添加路由错误${error}`)
          await store.dispatch('user/resetToken')
          next(`/login?redirect=${to.fullPath}`)
          NProgress.done()
        }
      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.fullPath}`)
      NProgress.done()
    }
  }
})

router.beforeResolve((to, from, next) => {
  // set page title
  document.title = to.meta.title
  next()
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})

import qs from 'qs'
import axios from 'axios'
import store from '@/store'
import { defaultUrl, virtualPath } from '../config/config.js'
import { MessageBox, Message, Loading } from 'element-ui'
import { getToken } from './auth.js'

// create an axios instance
const service = axios.create({
  baseURL: defaultUrl,
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 3000000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    config.global = config.global !== false
    config.loading = config.loading !== false
    config.loading && loadingService.show()

    // 标识系统为AJAX请求
    config.headers['X-Requested-With'] = 'XMLHttpRequest'

    if (getToken()) {
      config.headers['Authorization'] = 'Bearer ' + getToken()
    }

    if (!config.headers['Content-Type']) {
      config.headers['Content-Type'] = 'application/json;charset=UTF-8'
    }

    if (config.blob) {
      config.responseType = 'blob'
    }

    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const { config } = response
    config.loading && loadingService.close()
    if (config.blob) return response
    if (!config.global) return response.data

    const res = response.data

    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 0 && res.errcode !== '0') {
      Message({ message: res.message || res.msg || '接口错误...', type: 'error', duration: 5 * 1000, dangerouslyUseHTMLString: true })
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === 401 || res.code === 50008 || res.code === 50012 || res.code === 50014) {
        showReLoginMessage()
      }
      return Promise.reject(res)
    } else {
      return res
    }
  },
  error => {
    console.log(error)
    const { message, response, config } = error
    config.loading && loadingService.close()

    if (config && !config.global) return Promise.reject(error)

    if (response && response.status && (response.status === 401 || response.status === 403)) {
      showReLoginMessage()
    } else {
      Message({ message: message, type: 'error', duration: 5 * 1000 })
    }
    return Promise.reject(error)
  }
)

const loadingService = {
  number: 0,
  instance: null,
  show: function () {
    if (this.instance == null) {
      this.instance = Loading.service({ text: 'Loading...', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.08)' })
    }
    this.number = this.number + 1
  },
  close: function () {
    if (this.number === 1 || this.number === 0) {
      this.clean()
    } else {
      this.number = this.number - 1
    }
  },
  clean: function () {
    this.number = 0
    this.instance && this.instance.close()
    this.instance = null
  }
}

const showReLoginMessage = () => {
  const message = '登录过期，请重新登录'
  MessageBox.confirm(message, '提示', { confirmButtonText: '重新登录', type: 'warning' }).then(() => {
    store.dispatch('user/resetToken').then(() => { location.reload() })
  })
}

// 添加文件上传
service.upload = function (url, data, config) {
  if (data instanceof FormData) {
    config = config || {}
    config.contentType = false
    config.processData = false
    config.headers = { 'Content-Type': 'multipart/form-data' }
    return service.post(url, data, config)
  } else {
    Message({ message: '附件上传只支持FomrData格式...', type: 'error', duration: 5 * 1000 })
    return Promise.reject(new Error('附件上传只支持FomrData格式'))
  }
}

// 添加JSON调用
service.postForm = function (url, data, config = {}) {
  data = qs.stringify(config.data, { arrayFormat: 'brackets' })
  config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
  return service.post(url, data, config)
}

// 文件下载
service.download = function (url, data = {}, config = {}) {
  const defaults = { doc: document, target: '_blank' }
  config = { ...defaults, ...config }
  data.token = store.get(virtualPath + 'token')
  url = service.defaults.baseURL + url

  let old = null
  const doc = config.doc || document

  if (doc === document) {
    old = window.onbeforeunload
    window.onbeforeunload = null
  }

  const form = doc.createElement('form')
  form.setAttribute('method', 'post')
  form.setAttribute('action', url)
  form.setAttribute('target', config.target)

  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      const input = doc.createElement('input')
      input.setAttribute('name', key)
      input.value = data[key]
      form.appendChild(input)
    }
  }

  form.style.display = 'none'
  form.style.visibility = 'hidden'

  doc.body.appendChild(form)
  form.submit()

  if (form.parentNode != null) {
    form.parentNode.removeChild(form)
  }

  if (old != null) {
    window.onbeforeunload = old
  }
}

export default service

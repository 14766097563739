import { Message } from 'element-ui'

const session = {}

session.setItem = function (key, value) {
  if (window.sessionStorage) {
    const data = { data: value }
    window.sessionStorage.setItem(key, JSON.stringify(data))
  } else {
    Message({ message: 'This browser does NOT support sessionStorage', type: 'error', duration: 5 * 1000 })
  }
}

session.getItem = function (key) {
  var value = window.sessionStorage.getItem(key)
  if (!value) return null
  return JSON.parse(value).data
}

session.removeItem = function (key) {
  window.sessionStorage.removeItem(key)
}

session.clear = function () {
  window.sessionStorage.clear()
}

export default session

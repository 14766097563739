<template>
  <el-dialog v-if="visible" class="user-select" title="用户选择" :close-on-click-modal="false" :visible.sync="visible" width="750px" :append-to-body="true">
    <el-container :style="{ height: mainHeight + 'px' }">
      <el-main class="main-content" style="overflow:hidden">
        <el-input v-model="search.keyword" class="mb-2" prefix-icon="el-icon-search" placeholder="用户名" style="width:350px">
          <el-button slot="append" native-type="submit" icon="el-icon-search" />
        </el-input>
        <el-table ref="userTable" class="bottom-0" :data="users" stripe :row-style="{cursor:'pointer'}" row-key="id" :max-height="mainHeight-60" @select="handleSelect" @select-all="handleSelectAll" @row-click="handleClick">
          <el-table-column v-if="multiple" type="selection" header-align="center" align="center" width="50" />
          <el-table-column prop="id" label="用户">
            <template slot-scope="scope">
              {{ scope.row.realName }}/{{ scope.row.userName }}
            </template>
          </el-table-column>
        </el-table>
        <!-- <pagination v-show="records>0" :total="records" layout="prev, pager, next" :page.sync="search.currentPageIndex" :limit.sync="search.pageSize" class="text-right small" @pagination="pagerChange" /> -->
      </el-main>
    </el-container>
    <div v-if="multiple" class="selects p-1 el-tags">
      <div class="p-1">已选：{{ selects.length }} 名用户：</div>
      <div style="height:87px;overflow-y:auto;">
        <el-tag v-for="(user,index) in selects" :key="user.id" closable @close="removeSelect(index, user)">
          {{ user.realName }}/{{ user.userName }}
        </el-tag>
      </div>
    </div>
    <div v-if="multiple" slot="footer" class="dialog-footer">
      <el-button icon="el-icon-check" type="primary" @click="selectResult()">确 定</el-button>
      <el-button icon="el-icon-close" @click="visible = false">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { isArray } from '@/utils/validate'

export default {
  name: 'UserSelect',
  data () {
    return {
      value: null,
      users: [],
      selects: [], // 多选选择

      visible: true,
      multiple: false,
      callback: null,
      records: 0,
      search: {
        keyword: '',
        pageSize: 12,
        pageIndex: 1
      }
    }
  },
  computed: {
    mainHeight () {
      let height = document.documentElement.clientHeight * 0.7
      height = height - 55 * 3 - (this.multiple ? 100 : 0)
      return height > 600 ? 600 : (height < 340 ? 340 : height)
    }
  },
  methods: {
    initialize () {
      this.users = []
      this.selects = []
      this.records = 0
      this.search.keyword = ''
      this.getUsers()
    },
    setSelected () {
      if (!this.value) return
      if (this.multiple) {
        const arrays = isArray(this.value) ? this.value : this.value.split(/,|;|\|/)
        if (arrays.length === 0) return

        var query = {}
        query.pageSize = arrays.length
        query.pageIndex = 1
        query.userIds = arrays
        this.$http.post('/sys/user/getPrimaryUser', query).then(res => {
          this.selects = res.data
          this.users.forEach(row => { this.setRowSelection(row, this.includesUser(this.selects, row)) })
        })
      }
    },
    getUsers () {
      this.$http.post('/sys/user/getPrimaryUser', this.search).then((res) => {
        this.users = res.data
        this.$nextTick(() => {
          this.setSelected()
        })
      })
    },
    searchUser () {
      this.getUsers(1)
    },
    groupClick (group) {
      this.search.id = group.id
      this.search.keyword = ''
      this.group = group
      this.getUsers(1)
    },
    handleSelect (selection, user) {
      const index = this.selects.findIndex(item => item.id === user.id)
      if (this.includesUser(selection, user)) {
        if (index === -1) { this.selects.push(user) }
      } else {
        if (index !== -1) { this.selects.splice(index, 1) }
      }
    },
    handleSelectAll (selection) {
      if (selection.length > 0) {
        selection.forEach((user) => {
          if (!this.includesUser(this.selects, user)) { this.selects.push(user) }
        })
      } else {
        this.users.forEach(user => {
          const index = this.selects.findIndex(item => item.id === user.id)
          if (index !== -1) { this.selects.splice(index, 1) }
        })
      }
    },
    handleClick (user) {
      if (this.multiple) {
        const index = this.selects.findIndex(item => item.id === user.id)
        index === -1 ? this.selects.push(user) : this.selects.splice(index, 1)
        this.setRowSelection(user, index === -1)
      } else {
        this.visible = false
        if (this.callback) this.callback(user)
      }
    },
    removeSelect (index, user) {
      this.selects.splice(index, 1)
      this.setRowSelection(user, false)
    },
    setRowSelection (user, checked) {
      if (this.users.includes(user)) {
        this.$refs.userTable.toggleRowSelection(user, checked)
      } else {
        const row = this.users.find(o => o.id === user.id)
        if (row) {
          this.$refs.userTable.toggleRowSelection(row, checked)
        }
      }
    },
    includesUser (users, user) {
      return users.some(item => item.id === user.id)
    },
    pagerChange () {
      this.getUsers()
    },
    selectResult () {
      if (this.callback) {
        this.callback(this.selects)
      }
      this.visible = false
    },
    restoreState () {
      this.selects = []
    }
  }
}
</script>

<style scoped lang="scss">
.user-select {
  .main-content {
    padding: 0 10px;
  }
  .toolbar {
    padding: 5px;
    margin-bottom: 5px;
  }

  :deep(.search-bar) {
    margin: 5px;
  }
}
</style>
